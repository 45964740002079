<template>
  <div>
    <Table ref="table" />
  </div>
</template>

<script>
import Table from "./_components/table.vue";
export default {
  components: {
    Table,
  },
  methods: {
    setFilter(data) {
      this.$refs.table.setFilter(data);
    },
  },
};
</script>
